import React, { useContext } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import useSiteMetaData from "../hooks/useSiteMetadata"
import { LandingContext } from "../templates/landing"
import { colors } from "../theme"
import Box from "./box"
import Lang from "../images/lang.svg"


const grql = graphql`query {
    allContentYaml {
        nodes {
            id
            name
            fields {
                slug
            }
        }
    }
}`


const Dropdown = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  align-self: center;
  position: relative;
  font-size: 14px;
  padding: 0;
  
  &:hover > div {
    display: flex;
    top: 80%;
  }
  
  @media (max-width: 768px) {
    display: none;
  }
`
const Menu = styled(Box)`
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  flex-direction: column;
  display: none;
  top: 200%;
  transition: 0.2s all ease;
  z-index: 2;
  min-width: 8em;

  &:after {
    content: "";
   position: absolute;
   width: 20px;
   height: 20px;
   background: white;
   transform: rotate(45deg);
   top: -3px;
   left: 50%;
   margin-left: -10px;
   z-index: -1;
  }
`

const MenuItem = styled.a`
  display: flex;
  color: ${colors.blue};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: background-color .3s ease, color .3s ease;
  &:hover {
    background: ${colors.blue};
    color: white;
  }
  &:first-child {
    border-radius: 0;
  }
  &:last-child {
    border-radius: 0;
  }
`

const Toggle = styled.div`
  color: ${colors.blue};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 0;
`

const Icon = styled.img`
  width: 1rem !important;
`

const ItemContent = styled.div`
    padding: 0 20px;
    height: 30px;
    display: flex;
    align-items: center;
`

export default () => {

  const landingContext = useContext(LandingContext)
  const data = useStaticQuery(grql)
  const { lsItemName } = useSiteMetaData()

  const saveLangChoice = slug => {
    const lang = slug.match(/[a-z\-]+/)[0]
    localStorage.setItem(lsItemName, lang)
  }

  if (!landingContext) return null

  // English first, Asian last.
  const sortedLanguages = [
    data.allContentYaml.nodes.find(n => n.name === "English"),
    data.allContentYaml.nodes.find(n => n.name === "日本語"),
    data.allContentYaml.nodes.find(n => n.name === "中文 (简体)"),
    data.allContentYaml.nodes.find(n => n.name === "中文 (繁體)"),
  ]

  // Collect the other languages
  const remaining = data.allContentYaml.nodes.filter(
    n1 => !Boolean(sortedLanguages.find(n2 => n1.name === n2.name))
  )

  sortedLanguages.splice(1, 0, ...remaining)

  return (
    <>
      <Dropdown>

        <Toggle>
          <Icon src={Lang} alt="Language"/>&nbsp;&nbsp;Language
        </Toggle>

        <Menu>
          {sortedLanguages.map(({ id, name, fields: { slug } }) => (
            <MenuItem
              href={slug}
              key={id}
              onClick={saveLangChoice.bind(null, slug)}
            >
              <ItemContent>{name}</ItemContent>
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>

    </>
  )
}

import React from "react"
import Mistake from "../components/mistake"

// must be 3 items
export default {
  badEnglish: "I want visit Canada to vacation.",
  Correction: () => (
    <span>
      I want <Mistake>visit</Mistake> Canada <Mistake>to vacation</Mistake>.
    </span>
  ),
  improvements: [
    "I want to visit Canada on vacation.",
    "I want to go on vacation to Canada.",
    "I am planning to visit Canada on holiday.",
  ],
}

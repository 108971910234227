import React from "react"
import styled from "styled-components"
import pattern from "../images/pattern.svg"

import { breakpoint, colors } from "../theme"

const Container = styled.footer`
  position: absolute;
  background-color: ${colors.blue};
  background-image: url(${pattern});
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  width: 100vw;
  height: 400px;
  left: 0;
  top: 650px;
  @media (max-width: ${breakpoint}px) {
    top: 580px;
  }
  
  @media (max-width: 1140px) {
    top: 580px;
  }
  
  @media (max-width: 768px) {
    top: 540px;
    background-position: top;
    background-size: 100%;
    background-repeat: repeat;
  }
`

export default () => {
  return <Container></Container>
}

import React, { useContext } from "react"
import styled from "styled-components"

import Section from "../section"
import { LandingContext } from "../../templates/landing"
import ActionButton from "../actionButton"

const Container = styled(Section)`
  flex-direction: column;
  padding: 6rem 0;
  
  @media (max-width: 990px) {
    padding: 4rem 0 6rem;
  }
  @media (max-width: 578px) {
    padding: 4rem 4rem 6rem;
  }
  
`

const Header = styled.h2`
  text-align: center;
  
  @media (max-width: 1140px) {
    font-size: 1.3rem;
  }
  @media (max-width: 990px) {
    font-size: 1.3rem;
    margin-bottom: -2rem;
  }
  @media (max-width: 768px) {
    margin-bottom: -1rem;
  }
  @media (max-width: 578px) {
    font-size: 2rem;
    margin-bottom: 0rem;
  }
`

export default () => {
  const landingContext = useContext(LandingContext)

  if (!landingContext) return null
  const {
    text: { subHeader },
  } = landingContext

  return (
    <Container>
      <Header>{subHeader}</Header>
      <ActionButton />
    </Container>
  )
}

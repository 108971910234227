import React, { createContext } from "react"
import { graphql } from "gatsby"

import Layout from "./layout"
import SEO from "../components/seo"

import HeroSection from "../components/sections/hero.js"
import ExampleSection from "../components/sections/example"
import CallSection from "../components/sections/call"
import Background from "../components/background"

export const LandingContext = createContext()

export default ({ data }) => {
  const {
    font,
    name,
    text,
    fields: { slug },
  } = data.contentYaml

  return (
    <LandingContext.Provider
      value={{
        slug,
        name,
        text
      }}
    >
      <Layout font={font}>
        <HeroSection />
        <ExampleSection />
        <Background />
        <CallSection />
        <SEO title="The AI-Powered Grammar Checker" />
      </Layout>
    </LandingContext.Provider>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentYaml(fields: { slug: { eq: $slug } }) {
      name
      font
      text {
        header
        tagline
        button {
          sub
          text,
          target
        }
        examples {
          header
          tiles
        }
        subHeader
        footer {
          copyright
          links {
            target
            text
          }
        }
      }
      fields {
        slug
      }
    }
  }
`

import styled from "styled-components"
import { maxWidth } from "../theme"

export default styled.section`
  display: flex;
  width: 100%;
  max-width: ${maxWidth}px;
  padding: 2rem 0;
  justify-content: space-between;
    
  @media (max-width: 1140px) {
    max-width: 990px;
  } 
  @media (max-width: 990px) {
    max-width: 768px;
  } 
  @media (max-width: 768px) {
    max-width: 578px;
  }
  @media (max-width: 578px) {
    width: 100%;
    padding: 1rem 2rem 2rem;
  }
  
  @media (max-width: 400px) {
    width: 100%;
    padding: 1rem 1rem 2rem;
  }
`

import React, { useContext } from "react"
import styled from "styled-components"

import { colors, breakpoint } from "../theme"
import { LandingContext } from "../templates/landing"
import useSiteMetaData from "../hooks/useSiteMetadata"

const Container = styled.div`
  text-align: center;
  padding: 0;
  position: relative;
  top: 25px;

  @media (max-width: ${breakpoint}px) {
    padding: 0;
    margin-top: 3rem;
  }
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
 
`

const Button = styled.button`
  background: ${colors.blue};
    height: 3.9rem;
    border-radius: 1.9rem;
    color: #fff;
    border: none;
    outline: none;
    padding: 0 4rem;
    cursor: pointer;
    font-size: 1.3rem;
    
  @media (max-width: 1140px) {
    font-size: 1rem;
    height: 3rem;
    padding: 0 3rem;
  }
  @media (max-width: 578px) {
    font-size: 1.2rem;
    height: 3.6rem;
  }
`

const Sub = styled.div`
  color: ${colors.blue};
  opacity: 0.5;
  font-size: 0.9rem;
  padding-top: 0.7rem;
  
  @media (max-width: 1140px) {
    font-size: .8rem;
  }
  @media (max-width: 990px) {
    font-size: .9rem;
  }
`

export default () => {
  const landingContext = useContext(LandingContext)

  if (!landingContext) return null
  const {
    text: {
      button: { text, sub, target },
    },
  } = landingContext

  return (
    <Container>
      <a href={target} target="_blank" rel="noopener noreferrer">
        <Button>{text}</Button>
      </a>
      <Sub>{sub}</Sub>
    </Container>
  )
}

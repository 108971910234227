import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import Typist from "react-typist"

import useInterval from "../hooks/useInterval"
import { colors, breakpoint, animationDuration } from "../theme"
import Box from "./box"

const Container = styled(Box)`
  width: 100%;
  height: 300px;
  display: flex;
  padding: 0;
  position: relative;
  @media (max-width: 1140px) {
    height: 260px;
  }
  @media (max-width: 990px) {
    margin-top: 6rem;
    width: 100%;
  }
  @media (max-width: 578px) {
    width: calc(100%);
    padding: 0;
  }
`

const Left = styled.div`
  width: 50%;
  height: 100%;
  font-size: 15px;
`

const Hr = styled.hr`
  background: #F1F3FF;
  height: 100%;
  width: 2px;
  border: 0;
`

const Inner = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.8rem;
  padding-bottom: 2.5rem;
  text-align: center;
  
  @media (max-width: 578px) {
    padding: 0 .5rem;
  }
`
const Dots = styled.ul`
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 1rem;
    right: 1rem;
    list-style: none;
    margin: 0;
    
    & li {
      width: 10px;
      height: 10px;
      background: #F1F3FF;
      border-radius: 100%;
      margin-left: 10px;
    }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
`
const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const Right = styled(Inner)`
  animation: ${animationDuration / 1000}s
    ${({ number }) => (number === 3 ? fadeIn : fadeInOut)} ease-out;
  width: 50%;
  height: 100%;
  color: ${colors.blue};
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0;
  font-size: 15px;
`

const NumberContainer = styled.div`
  position: sticky;
  display: flex;
  padding-top: 1rem;
  justify-content: center;
`
const Number = styled.div`
  padding: 0 0.8rem;
  color: ${({ active }) => (active ? colors.blue : "rgba(56, 75, 245, 0.2)")};
`

export default ({ badEnglish, Correction, improvements }) => {
  const [animationComplete, setAnimationComplete] = useState(false)
  const [number, setNumber] = useState(0)
  const [key, setKey] = useState(0)
  useInterval(() => {
    number < 3 && setNumber(number + 1)
  }, animationDuration)
  useInterval(() => {
    setKey(key + 1)
    setNumber(0)
    setAnimationComplete(false)
  }, 4 * animationDuration)

  return (
    <Container>
      <Dots>
        <li />
        <li />
        <li />
      </Dots>
      <Left>
        <Inner>
          {animationComplete ? (
            <Correction />
          ) : (
            <Typist
              onTypingDone={() =>
                setTimeout(() => setAnimationComplete(true), 500)
              }
              key={key}
            >
              {badEnglish}
            </Typist>
          )}
        </Inner>
      </Left>
      <Hr/>
      <Right key={number} number={number}>
        {animationComplete && number > 0 && (
          <>
            <div>{improvements[number - 1]}</div>
            <NumberContainer>
              <Number active={number === 1}>1</Number>
              <Number active={number === 2}>2</Number>
              <Number active={number === 3}>3</Number>
            </NumberContainer>
          </>
        )}
      </Right>
    </Container>
  )
}

import React, { useState, useContext } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { Menu, X } from "react-feather"

import useSiteMetaData from "../hooks/useSiteMetadata"
import { LandingContext } from "../templates/landing"
import { colors } from "../theme"
import Box from "./box"

const grql = graphql`query {
      allContentYaml {
        nodes {
          id
          name
          fields {
            slug
          }
        }
      }
    }`

const Dropdown = styled.div`
  position: fixed;
  right: 1rem;
  z-index: 3;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: ${({ open }) => (open ? 'transparent' : '#ffffff85')};
  
  @media (min-width: 768px) {
    display: none;
  }
`

const Menus = styled(Box)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  transition: 0.3s all ease;
  background: ${colors.blue};
  padding: 4rem 2rem;
  border-radius: 0;
  box-shadow: none;
  top: 0;
  align-items: flex-start;
  flex-direction: column;
  z-index: 2;
  left: ${({ open }) => (open ? 0 : '-1000px')};
  
  @media (min-width: 768px) {
    display: none;
  }
`

const MenuItem = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  width: 100%;
`


export default () => {
  const [open, setOpen] = useState(false)
  const landingContext = useContext(LandingContext)

  const data = useStaticQuery(grql)
  const { lsItemName } = useSiteMetaData()

  const saveLangChoice = slug => {
    const lang = slug.match(/[a-z\-]+/)[0]
    localStorage.setItem(lsItemName, lang)
    setOpen(false)
  }

  if (!landingContext) return null

  return (
    <>
      <Dropdown open={open}>
        {open ?
          <X color="white" size={30} onClick={setOpen.bind(this, false)}/>  :
          <Menu color={colors.blue} size={30} onClick={setOpen.bind(this, true)}/>
        }
      </Dropdown>
      <Menus open={open}>
        {data.allContentYaml.nodes.map(({ id, name, fields: { slug } }) => (
          <MenuItem href={slug} key={id} onClick={saveLangChoice.bind(null, slug)} >
            {name}
          </MenuItem>
        ))}
      </Menus>
    </>
  )
}

import React, { useContext } from "react"
import styled from "styled-components"

import Section from "../section"
import Example from "../example"
import { LandingContext } from "../../templates/landing"
import { breakpoint, colors } from "../../theme"
import pattern from "../../images/pattern.svg"

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11rem 0 0;
  z-index: 1;
  
  @media (max-width: 1140px) {
    padding: 9rem 0 0;
  }
  
  @media (max-width: 990px) {
    flex-direction: column;
    padding: 4rem 0 0;
  }
  
  @media (max-width: 768px) {
    padding: 2rem 0 0;
  }
  
  @media (max-width: 578px) {
    background-color: ${colors.blue};
    background-image: url(${pattern});
    background-position: top;
    background-size: 100%;
    background-repeat: repeat;
  }
  
`

const Header = styled.h2`
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
  padding: 2rem 0;
  text-align: center;
  max-width: 50rem;
  
  @media (max-width: 990px) {
    padding: 1rem 0 2rem 0;
    font-size: 1.5rem;
  }
  @media (max-width: 768px) {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  @media (max-width: 578px) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  @media (max-width: 400px) {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    padding: 2rem;
    margin-top: -2rem;
  }
`

const Examples = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 3.5rem;
    
  @media (max-width: 990px) {
    margin-top: 0;
  }
  
  @media (max-width: 578px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 3rem;
  }
  
  @media (max-width: 400px) {
    padding-bottom: 0;
  }
`

export default () => {
  const landingContext = useContext(LandingContext)

  if (!landingContext) return null
  const {
    text: {
      examples: { header, tiles },
    },
  } = landingContext

  return (
    <Container>
      <Header>{header}</Header>
      <Examples>
        {tiles.map((tile, i) => (
          <Example key={i} i={i} text={tile} />
        ))}
      </Examples>
    </Container>
  )
}

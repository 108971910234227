import React from "react"
import styled from "styled-components"

import Box from "./box"
import Hr from "./hr"
import Mistake from "../components/mistake"
import styleIcon from "../images/style-icon.svg"
import { colors } from "../theme"

const Container = styled(Box)`
  margin: 0;
  height: 400px;
  width: 480px;
  max-width: 90vw;
  flex-direction: column;
  padding: 2rem 3rem;
  margin-bottom: 5rem;
  
  @media (max-width: 1140px) {
    height: 360px;
    width: 420px;
    padding: 2rem 3rem;
    margin-bottom: 4rem;
  }
  
  @media (max-width: 990px) {
    height: 330px;
    width: calc(50% - 2.5rem);
    padding: 0 2rem;
    margin-bottom: 2.5rem;
  }
  
  @media (max-width: 768px) {
    height: 300px;
    width: calc(50% - 1.5rem);
    padding: 1.5rem 1rem;
    margin-bottom: 1.5rem;
  }
  
  @media (max-width: 578px) {
    margin-bottom: 2.5rem;
    padding: 2rem;
    height: 325px;
    width: 355px;
  }
`

const Half = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0;
  align-items: center;
  font-size: 14px;
  
  @media (max-width: 1140px) {
    font-size: 14px;
  }
  @media (max-width: 990px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 15px;
  }
  @media (max-width: 578px) {
    font-size: 15px;
  }
      
`

const Top = styled(Half)`
  height: 60%;
`

const Bottom = styled(Half)`
  height: 40%;
`

const Text = styled.div`
  color: ${colors.blue};
  line-height: 1.5rem;
 
`

const Underline = styled.span`
  border-bottom: 2px solid ${colors.blue};
`

const ExampleText = styled.span`
  line-height: 31px;
`

const Item = styled.div`
  display: flex;
  padding: 0 0;
  line-height: 31px;
  font-size: 13px;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 9px;
  
  @media (max-width: 990px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 13px;
  }
  @media (max-width: 578px) {
    font-size: 15px;
  }
`

const Number = styled.div`
  align-items: center;
  background: #F1F3FF;
  border-radius: 12.5px;
  color: ${colors.blue};
  display: flex;
  height: 25px;
  justify-content: center;
  line-height: 1.5rem;
  margin-right: 0.5rem;
  min-width: 25px;
`

const TintedText = styled.div`
  line-height: 31px;
  //font-size: 13px;
  opacity: 1;
`

const Blue = styled.span`
  color: ${colors.blue};
`

const LargeText = styled.span`
  font-size: 1.1rem;
  line-height: 1.8rem;
  
  @media (max-width: 1140px) {
    font-size: 14px;
  }
  @media (max-width: 990px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 15px;
  }
  @media (max-width: 578px) {
    font-size: 16px;
  }
`

const Image = styled.img`
  margin-top: 1px;
  margin-right: 0.8rem;
`

const examples = [
  () => (
    <ExampleText>
      <Underline>
        I want to speak English <Mistake>good</Mistake>
      </Underline>
      . <Mistake>It's</Mistake> hard <Mistake>for</Mistake> learn without{" "}
      <Mistake>good</Mistake> teacher.
    </ExampleText>
  ),
  () => (
    <div>
      <Item>
        <Number>1</Number>
        <TintedText>
          I want to speak English <Blue>well.</Blue>
        </TintedText>
      </Item>
      <Item>
        <Number>2</Number>
        <TintedText>I want to speak English.</TintedText>
      </Item>
      <Item>
        <Number>3</Number>
        <TintedText>
          I <Blue>wish I could</Blue> speak <Blue>good English</Blue>
        </TintedText>
      </Item>
    </div>
  ),
  () => (
    <div>
      <Item>
        <Image src={styleIcon} alt="Writing Style" width={20} height={20} />
        <LargeText>Formal Tone</LargeText>
      </Item>
      <Item>
        <Image src={styleIcon} alt="Writing Style" width={20} height={20} />
        <LargeText>Informal Tone</LargeText>
      </Item>
      <Item>
        <Image src={styleIcon} alt="Writing Style" width={20} height={20} />
        <LargeText>Concise</LargeText>
      </Item>
    </div>
  ),
  () => (
    <div>
      <Item>
        <Number>En</Number>
        <TintedText>
          I <Blue>wish I could</Blue> speak <Blue>English well</Blue>
        </TintedText>
      </Item>
      <Item>
        <Number>De</Number>
        <TintedText>Ich wünschte, ich könnte gut Englisch sprechen</TintedText>
      </Item>
      <Item>
        <Number>Es</Number>
        <TintedText>Desearía poder hablar bien inglés</TintedText>
      </Item>
    </div>
  ),
]

export default ({ text, i }) => {
  const Content = examples[i]
  return (
    <Container>
      <Top>
        <Content />
      </Top>
      <Hr />
      <Bottom>
        <Text>{text}</Text>
      </Bottom>
    </Container>
  )
}

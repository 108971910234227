import styled from "styled-components"

export default styled.div`
  background: white;
  border-radius: 0.8rem;
  box-shadow: 
    0 -10px 25px -5px rgba(0,0,0,0.1), 
    0 10px 7px -5px rgba(0,0,0,0.04);
  display: flex;
  align-items: center;
  padding: 1rem 0;
`

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Header from "../components/header"
import Footer from "../components/footer"

import "./layout.css"

if(typeof localStorage !== 'undefined') {
  const lang = localStorage.getItem('lang')
  const isAsianLanguage = {
    'zh-s': true,
    'zh-t': true,
    'ja': true,
  }

  if(isAsianLanguage[lang]) {
    require(`./layout-font-${lang}.css`)
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ font }) => font ? font : 'Museo Sans  '};
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100vw;
`

const Layout = ({ children, font }) => {

  return (
    <Container font={font}>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

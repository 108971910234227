import React from "react"
import styled from "styled-components"

import Nav from "./Nav"
import MobileNav from "./MobileNav"
import { maxWidth } from "../theme"

const Header = styled.header`
  width: 100%;
  max-width: ${maxWidth}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0 6rem;
  
  @media (max-width: 1140px) {
    padding: 3rem 2rem 4.5rem;
    
    img {
      width: 140px;
    }
  }
   @media (max-width: 990px) {
    padding: 2rem 2rem 4rem;
  }
  @media (max-width: 768px) {
    padding: 2rem 2rem 3rem;
  }
  @media (max-width: 578px) {
    width: 100%;
    padding: 2rem 1rem 3rem;
  }
`

export default () => (
  <Header>
    <a href="/">
      <img src="/logo.svg" alt="Logo" />
    </a>
    <Nav />
    <MobileNav />
  </Header>
)

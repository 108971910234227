import React, { useContext } from "react"
import styled from "styled-components"

import ActionButton from "../actionButton"
import AnimationBox from "../animationbox"
import Section from "../section"
import { LandingContext } from "../../templates/landing"
import { colors, breakpoint } from "../../theme"
import phrases from "../../content/phrases"

const Container = styled(Section)`
  padding: 0;
  @media (max-width: ${breakpoint}px) {
    flex-direction: column;
  }
`

const Flex = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  z-index: 1;
  @media (max-width: ${breakpoint}px) {
    height: 50%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`

const AnimationBoxContainer = styled(Flex)`
  width: 55%;
  @media (max-width: 400px) {
    padding: 0;
  }
`

const Header = styled.h2`
  color: ${colors.blue};
  font-weight: 300;
  font-size: 2.2rem;
  text-align: left;
  max-width: 20rem;
  margin-top: 0;
  margin-bottom: 1rem;
  
  @media (max-width: ${breakpoint}px) {
    text-align: center;
    max-width: 100%;
  }
  
  @media (max-width: 1140px) {
    font-size: 2rem;
  }
`

const Tagline = styled.h4`
  color: ${colors.dark};
  font-weight: 500;
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.5;
  max-width: 25.5rem;
  margin: 0;
  @media (max-width: ${breakpoint}px) {
    text-align: center;
    max-width: 100%;
  }
`

export default () => {
  const landingContext = useContext(LandingContext)

  if (!landingContext) return null
  const {
    text: { header, tagline },
  } = landingContext

  return (
    <Container>
      <Flex>
        <div>
          <Header>{header}</Header>
          <Tagline>{tagline}</Tagline>
        </div>
        <ActionButton />
      </Flex>
      <AnimationBoxContainer>
        <AnimationBox {...phrases} />
      </AnimationBoxContainer>
    </Container>
  )
}

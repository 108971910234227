import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import canada from "../images/canada.svg"

import Hr from "./hr"
import { colors, maxWidth } from "../theme"

const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  max-width: ${maxWidth}px;
  font-size: 14px;
  
  @media (max-width: 578px) {
    flex-direction: column;
    
  }
`

const Links = styled.div`
  @media (max-width: 578px) {
    display: flex;
    flex-direction: column;
  }
`
const Copyright = styled.div`
  @media (max-width: 578px) {
    font-size: 1rem;
  }
`

const Image = styled.img`
  padding-right: 5px;
`

const Link = styled.a`
  padding-right: 2rem;
  text-decoration: none;
  color: ${colors.blue};
  font-weight: 500;
  opacity: 0.8;
  
  @media (max-width: 578px) {
    padding: 0;
    margin-bottom: 2.5rem;
    font-size: 1rem;
  }
`

const SectionBreak = styled(Hr)`
  width: calc(100% - 4rem);
  max-width: calc(${maxWidth}px - 4rem);
`

export default () => {
  // TODO don't hardcode languge
  const data = useStaticQuery(graphql`
    query {
      contentYaml(fields: { slug: { eq: "/en/" } }) {
        text {
          footer {
            copyright
            links {
              text
              target
            }
          }
        }
      }
    }
  `)

  const { links, copyright } = data.contentYaml.text.footer

  return (
    <>
      <SectionBreak />
      <Footer>
        <Links>
          {links.map(({ text, target }) => (
            <Link key={text} href={target}>
              {text}
            </Link>
          ))}
        </Links>
        <Copyright>
          <Image src={canada} alt="Made in Canada" width="25" height="13" />
          {`${copyright}`}
        </Copyright>
      </Footer>
    </>
  )
}
